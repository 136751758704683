import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import AuthContent from "../components/content/auth"
import Footer from "../components/footer"

const AuthPage = () => (
  <>
    <SEO title="Authentication" />
    <Nav />
    <AuthContent />
    <Footer />
  </>
)

export default AuthPage